<!--
  {{targets |json}}
-->

<div  *ngIf="targetsForView.length > 0" class="row mb-3">
  <div class="col">
    <div class="card">
      <div class="card-header lead">
        Targets
      </div>
      <div class="card-body">
        <div  class="row">
          <div class="col table-responsive">
            <table class="table">

              <thead>
                <tr>
                  <th scope="col">Demographic</th>
                  <th scope="col">Options</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of targetsForView">
                  <td>{{item.questionKey}}</td>
                  <td>{{item.optionText}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
    </div>
  </div>
</div>
