import { AuthService } from '../../../_services/auth.service';
import { DropDownService } from '../../../_services/drop-down.service';
import { UtilsService } from '../../../_services/utils.service';
import { DatePipe, formatDate } from '@angular/common';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import {
  Component,
  ElementRef,
  HostListener,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { ProjectService } from '../../../_services/project.service';
import { ClipboardService } from 'ngx-clipboard';
import { VendorService } from '../../../_services/vendor.service';
import { Vendor } from '../../../_models/vendor';
import { Constants, SurveyStatus } from '../../../_models/constants';
import { ExportParticipantsFilter } from '../../../_models/export-participants-filter';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ProjectParticipant } from '../../../_models/participants';

@Component({
  selector: 'app-view-project',
  templateUrl: './view-project.component.html',
  styleUrls: ['./view-project.component.css'],
})
export class ViewProjectComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('closeCloneModal') private closeCloneModal: ElementRef;
  @ViewChild('modal') public myModal: any;
  @ViewChild('op') public exportParticipantOverlayPanel: OverlayPanel;
  form: FormGroup;
  loading = false;
  cloning = false;
  downloading = false;
  submitted = false;
  quotaMet = false;
  projectlist = '';
  projectId = '';
  project: any;
  markers: string;
  otherProjects: any[] = [];
  closeResult = '';
  preparticipants: any[] = [];
  participants: any[] = [];
  earliestParticipant: ProjectParticipant;
  routeSub: any;
  piiInfo = '';
  currentVendor: any;
  vendorExportDropdownValues: Vendor[] = [];

  surveyStatusExportDropdownValues = Constants.SURVEY_STATUS;
  exportParticipantForm: FormGroup;

  vendorsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    allowSearchFilter: true,
    itemsShowLimit: 2,
  };

  surveyStatusDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'code',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    allowSearchFilter: true,
    itemsShowLimit: 4,
  };

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private projectService: ProjectService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private dd: DropDownService,
    private datePipe: DatePipe,
    private utils: UtilsService,
    public auth: AuthService,
    private clipboard: ClipboardService,
    private vendorService: VendorService,
  ) {
    this.routeSub = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.projectId = this.route.snapshot.params['id'];

        if (
          this.project === undefined ||
          this.projectId !== this.project.projectId
        ) {
          this.project = 'loading';
          this.loadProject();
        }
      }
    });

    this.clipboard.configure({ cleanUpAfterCopy: true });
  }

  public ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    if (this.project != 'loading') {
      this.projectId = this.route.snapshot.params['id'];
      this.loadProject();
    }
    this.exportParticipantForm = this.formBuilder.group(
      {
        startDate: [''],
        endDate: [formatDate(new Date(), 'yyyy-MM-dd', 'en')],
        vendors: [''],
        surveyStatus: [''],
      },
      { validators: this.dateRangeValidator('startDate', 'endDate') },
    );

    this.exportParticipantForm.controls.surveyStatus.setValue(
      this.surveyStatusExportDropdownValues,
    );
  }

  copyContent(content: string) {
    this.clipboard.copyFromContent(content);
    this.toastr.info('Copied to clipboard');
  }

  formatDate(createDate: string, includeTime: boolean) {
    const date = new Date(createDate).toLocaleDateString();
    const time = new Date(createDate).toLocaleTimeString();
    return includeTime ? date + ' ' + time : date;
  }

  loadProject() {
    this.getProject();
    this.fetchOtherProjects();
    this.form = this.formBuilder.group({
      segmentId: ['', Validators.required],
      completeUrl: ['', Validators.required],
      termUrl: ['', Validators.required],
      oqUrl: ['', Validators.required],
      qcUrl: ['', Validators.required],
      signingKey: [''],
      signRedirects: ['', Validators.required],
    });
  }

  get f() {
    return this.form.controls;
  }

  toggleModal(content: any, segment: any) {
    this.bindForm(segment);
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {},
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        },
      );
  }

  isQuotaMet(): boolean {
    if (this.project?.quotaByStarts) {
      return (
        this.project?.starts >= this.project?.fullLaunchQuota &&
        this.project?.fullLaunchQuota > 0
      );
    } else {
      return (
        this.project?.completes >= this.project?.fullLaunchQuota &&
        this.project?.fullLaunchQuota > 0
      );
    }
  }

  bindForm(segment: any) {
    if (segment) {
      this.form = this.formBuilder.group({
        segmentId: [segment.id, Validators.required],
        completeUrl: [segment.completeUrl, Validators.required],
        termUrl: [segment.termUrl, Validators.required],
        oqUrl: [segment.oqUrl, Validators.required],
        qcUrl: [segment.qcUrl, Validators.required],
        signingKey: [
          segment.signingKey,
          segment.signRedirects === true
            ? Validators.required
            : Validators.nullValidator,
        ],
        signRedirects: [segment.signRedirects, Validators.required],
      });
    }
  }

  signRedirectsChanged(e) {
    if (e.target.checked) {
      this.form.controls['signingKey'].setValidators([Validators.required]);
      this.form.controls['signingKey'].updateValueAndValidity();
    } else {
      this.form.controls['signingKey'].setValidators([
        Validators.nullValidator,
      ]);
      this.form.controls['signingKey'].updateValueAndValidity();
    }
  }

  editProject() {
    this.router.navigate(['/projects/edit/' + this.projectId]);
  }

  // this is needed to trigger validation for start/end date inputs
  // via onchange
  onDownloadParticipantDatePickerChange() {
    this.exportParticipantForm.controls.startDate.updateValueAndValidity();
    this.exportParticipantForm.controls.endDate.updateValueAndValidity();
  }

  cloneProject() {
    this.cloning = true;
    this.projectService.clone(this.projectId).subscribe(
      (data) => {
        this.projectId = data.id;
        this.cloning = false;
        this.closeCloneModal.nativeElement.click();

        const url = this.router.serializeUrl(
          this.router.createUrlTree(['/projects/view/' + data.id]),
        );
        window.open(url, '_blank');
        this.toastr.success('Project cloned');
      },
      (error) => {
        this.toastr.error('Failed to clone project');
        this.cloning = false;
      },
    );
  }

  onSubmit(content: any) {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.loading = true;
    this.projectService
      .updateUrls(this.form.value)
      .pipe(first())
      .subscribe({
        next: () => {
          this.toastr.success('Vendor urls updated');
          this.getProject();
          this.loading = false;
        },
        error: (error) => {
          this.toastr.warning(error);
          this.loading = false;
        },
      });
    this.modalService.dismissAll();
  }

  onSubmitExportParticipants() {
    if (!this.exportParticipantForm.valid) {
      return;
    }

    this.downloading = true;
    const exportFormData: ExportParticipantsFilter = {
      startDate:
        this.exportParticipantForm.controls.startDate.value === ''
          ? null
          : this.exportParticipantForm.controls.startDate.value,
      endDate:
        this.exportParticipantForm.controls.endDate.value === ''
          ? null
          : this.exportParticipantForm.controls.endDate.value,
      vendors:
        this.exportParticipantForm.controls.vendors.value.length === 0
          ? null
          : this.exportParticipantForm.controls.vendors.value,
      surveyStatus:
        this.exportParticipantForm.controls.surveyStatus.value.length === 0
          ? null
          : this.exportParticipantForm.controls.surveyStatus.value,
    };

    this.projectService
      .downloadParticipantsCSV(this.projectId, exportFormData)
      .subscribe({
        next: (data) => {
          const link = document.createElement('a');
          link.download = data.fileName;
          link.href = data.fileUrl;
          link.click();
          this.downloading = false;
        },
        error: (e) => {
          this.toastr.error('Failed to download participants');
          this.downloading = false;
        },
        complete: () => {
          // close export participants overlay panel;
          this.exportParticipantOverlayPanel.hide();
        },
      });
  }

  getProject() {
    this.projectService.find(this.projectId).subscribe({
      next: (data) => {
        this.project = data;
        this.fetchParticipants();

        let projectVendor: Vendor[] = [];
        this.project?.segments.forEach((element) => {
          let vendor: Vendor = {
            id: element.vendorId,
            name: element.vendorName,
            url: '',
            instanceId: '',
          };
          projectVendor.push(vendor);
        });
        this.vendorExportDropdownValues = projectVendor;
        this.exportParticipantForm.controls.vendors.setValue(
          this.vendorExportDropdownValues,
        );
      },
      error: () => {
        this.toastr.error('Failed to fetch project');
      },
      complete: () => {
        // this.toastr.success('Fetched instances');
        this.markers = this.project?.demographics?.markers
          ?.map((u) => u.name)
          .join(', ');
        this.quotaMet = this.isQuotaMet();

        this.piiInfo = [
          this.project?.piiName ? 'Name' : null,
          this.project?.piiFirstName ? 'First Name' : null,
          this.project?.piiEmail ? 'Email' : null,
          this.project?.piiEmailValidation ? 'Email Validation' : null,
          this.project?.piiPhone ? 'Phone' : null,
          this.project?.piiPhoneValidation ? 'Phone Validation' : null,
          this.project?.piiAddress ? 'Address' : null,
          this.project?.piiState ? 'State' : null,
          this.project?.piiZipPlusSix ? 'Zip +' : null,
        ]
          .filter(Boolean)
          .join(', ');
      },
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  fetchParticipants() {
    this.projectService.projectParticipants(this.projectId).subscribe(
      (data) => {
        this.preparticipants = data;
      },
      (error) => {
        this.toastr.error('Failed to fetch project');
      },
      () => {
        // filter participants down by status and only show those results in the performance data.
        // Use the segmentStatus on the participant record, matched to the current project's ProjectStatus.
        if (this.project.projectStatus.toLowerCase() === 'test') {
          this.preparticipants = this.preparticipants.filter(
            (x) => x.segmentStatus?.toLowerCase() === 'test',
          );
        } else {
          this.preparticipants = this.preparticipants.filter(
            (x) =>
              x.segmentStatus?.toLowerCase() !== 'test' || !x.segmentStatus,
          );
        }

        //finally fix dates
        this.preparticipants.forEach((element) => {
          element.day = this.datePipe.transform(element.inTime, 'yyyy-MM-dd');
          element.inTime = this.datePipe.transform(element.inTime, 'medium');
          element.outTime = this.datePipe.transform(element.outTime, 'medium');
        });

        // this will get the first participant
        // Needed to be used to populate the export participant start date input
        if (this.preparticipants.length > 0) {
          this.earliestParticipant = this.preparticipants.reduce(
            function (pre, cur) {
              return Date.parse(pre.inTime) > Date.parse(cur.inTime)
                ? cur
                : pre;
            },
          );
          // Set the date value to the earliest date
          this.exportParticipantForm.controls.startDate.setValue(
            this.datePipe.transform(
              this.earliestParticipant.inTime,
              'yyyy-MM-dd',
            ),
          );
        } else {
          // set todays date if no participants
          this.exportParticipantForm.controls.startDate.setValue(
            formatDate(new Date(), 'yyyy-MM-dd', 'en'),
          );
        }

        // trigger onchange method to trigger validation
        this.onDownloadParticipantDatePickerChange();

        this.participants = this.preparticipants;
      },
    );
  }

  showSampleIds(event: any) {
    event.preventDefault();
    this.toastr.info(this.project.sampleIds, 'Sample of Ids in file', {
      closeButton: true,
      timeOut: 6000,
      progressBar: true,
      enableHtml: true,
    });
  }

  showSampleTargetVIDs(event: any) {
    event.preventDefault();
    this.toastr.info(this.project.sampleTargetVIDs, 'Sample of Ids in file', {
      closeButton: true,
      timeOut: 6000,
      progressBar: true,
      enableHtml: true,
    });
  }

  fetchOtherProjects() {
    this.dd.projects().subscribe(
      (data) => {
        this.otherProjects = data;
      },
      (error) => {
        this.toastr.error('Failed to fetch project');
      },
      () => {
        this.otherProjects = this.otherProjects.filter((proj) =>
          this.project?.dedupeOtherProjects?.includes(proj.value),
        );
        this.projectlist = this.otherProjects.map((x) => x.option).join(', ');
      },
    );
  }

  setCurrentVendor(vendor: any) {
    this.currentVendor = vendor;
  }

  dateRangeValidator(startDateKey: string, endDateKey: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const formGroup = control as FormGroup;
      const startDate = formGroup.get(startDateKey)?.value;
      const endDate = formGroup.get(endDateKey)?.value;

      if ((startDate && !endDate) || (!startDate && endDate)) {
        formGroup.get(startDateKey)?.setErrors({ bothRequiredInvalid: true });
        formGroup.get(endDateKey)?.setErrors({ bothRequiredInvalid: true });
        return { BothRequiredInvalid: true };
      }

      if (startDate && endDate && startDate > endDate) {
        this.exportParticipantForm.controls.endDate.setErrors({
          invalid: true,
        });
        this.exportParticipantForm.controls.startDate.setErrors({
          invalid: true,
        });
        return { invalid: true };
      }

      return null;
    };
  }
}
