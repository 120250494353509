<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="#">Home</a></li>
    <li class="breadcrumb-item"><a href="/projects">Projects</a></li>
    <li class="breadcrumb-item active" aria-current="page">
      View {{ project?.dashCode }}
    </li>
  </ol>
</nav>
<!--
{{project|json}}
-->

<!-- Clone Confirm Modal -->
<div class="modal fade" id="cloneModal" tabindex="-1" role="dialog" aria-labelledby="cloneModalTitle"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="cloneModalTitle">Are you sure?</h5>
      </div>
      <div class="modal-body">
        Are you sure you want to clone this project?
      </div>
      <div class="modal-footer">
        <button #closeCloneModal type="button" class="btn btn-secondary" data-bs-dismiss="modal"
          aria-label="Close">Cancel</button>
        <button [disabled]="cloning" (click)="this.cloneProject()" class="btn btn-primary">
          <span *ngIf="cloning" class="spinner-border spinner-border-sm mr-1"></span>
          Yes
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Callback Modal -->
<div class="modal fade" id="callbackModal" tabindex="-1" role="dialog" aria-labelledby="callbackModalTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="callbackTitle">Client Callback Examples</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div #callbackModal class="modal-body">
        <div class="row mb-3">
          <div class="card shadow-sm">
            <div class="card-header lead">
              <span>Complete <small>(&serverCall=true)</small></span>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="clearfix"></div>
                  <a [href]="project?.completeUrl" target="_blank" class="card-link me-2">{{ project?.completeUrl }}</a>
                  <i ngxClipboard [cbContent]="project?.completeUrl" [container]="callbackModal"
                    (click)="copyContent(project?.completeUrl)" class="fa fa-copy" style="font-size: 12px"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="card shadow-sm">
            <div class="card-header lead">
              <span>QC <small>(&serverCall=true)</small></span>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="clearfix"></div>

                  <a [href]="project?.qcUrl" target="_blank" class="card-link me-2">
                    {{ project?.qcUrl }}</a>
                  <i ngxClipboard [cbContent]="project?.qcUrl" [container]="callbackModal"
                    (click)="copyContent(project?.qcUrl)" class="fa fa-copy" style="font-size: 12px"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="card shadow-sm">
            <div class="card-header lead">
              <span>OQ <small>(&serverCall=true)</small></span>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="clearfix"></div>
                  <a [href]="project?.oqUrl" target="_blank" class="card-link me-2">
                    {{ project?.oqUrl }}</a>
                  <i ngxClipboard [cbContent]="project?.oqUrl" [container]="callbackModal"
                    (click)="copyContent(project?.oqUrl)" class="fa fa-copy" style="font-size: 12px"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="card shadow-sm">
            <div class="card-header lead">
              <span>Term <small>(&serverCall=true)</small></span>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="clearfix"></div>
                  <a [href]="project?.termUrl" target="_blank" class="card-link me-2">
                    {{ project?.termUrl }}</a>
                  <i ngxClipboard [cbContent]="project?.termUrl" [container]="callbackModal"
                    (click)="copyContent(project?.termUrl)" class="fa fa-copy" style="font-size: 12px"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mb-3">
  <div class="col">
    <div class="card">
      <div class="card-header lead">
        <span class="fw-bolder">
          {{ project?.dashCode }} {{ project?.projectName }}</span>
        <button type="button" class="btn float-end shadow-none buttonBar" title="Client Callback URLs"
          data-bs-toggle="modal" data-bs-target="#callbackModal">
          <i class="bi bi-info-circle"></i>
        </button>

        <button class="btn float-end shadow-none buttonBar" title="Download Participants"
          (click)="op.toggle($event)">
          <span *ngIf="downloading" class="spinner-border spinner-border-sm mr-1"></span>
          <i *ngIf="!downloading" class="bi bi-download"></i>
        </button>

        <a class="btn float-end shadow-none buttonBar" title="Edit Project" (click)="editProject()"><i
            class="bi bi-pencil-square"></i></a>
        <button class="btn float-end shadow-none buttonBar" title="Clone Project" [disabled]="loading"
          data-bs-toggle="modal" data-bs-target="#cloneModal">
          <span *ngIf="cloning" class="spinner-border spinner-border-sm mr-1"></span>
          <i *ngIf="!cloning" class="bi bi-layers"></i>
        </button>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div *ngIf="quotaMet && project?.projectStatus === 'Paused' && project?.enableQuotas"
              class="alert alert-info" role="alert">
              The quota for this project has been met. The status cannot be set to Live.
            </div>
          </div>
        </div>
        <div class="row">
          <div class="xs-col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            Client: <a href="/clients/edit/{{ project?.clientId }}" target="_blank" class="card-link">{{ project?.clientName }}</a>
            <div class="clearfix"></div>
            Deadline: {{ this.formatDate(project?.deadline, false) }}
            <div class="clearfix"></div>
            Status: {{ project?.projectStatus?.replace('Full Launch','Live') }}
            <div class="clearfix"></div>
            Country: {{ project?.country }}
            <div class="clearfix"></div>
            Language: {{ project?.languageName }} ({{ project?.languageCode }})
            <ng-container *ngIf="project?.enableVendorPassThruVariable">
              <div class="clearfix"></div>
              Vendor Pass-through Variable: {{ project?.vendorPassThruVariableName }}
            </ng-container>
            <ng-container *ngIf="this.auth.user.userDetails.dash != true">
              <div class="clearfix"></div>
              Markers: {{ markers }}
              <div class="clearfix"></div>
              Auto Generate Referral Code: {{ project?.autogenReferralCode }}
            </ng-container>
          </div>
          <div class="xs-col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <!--
                    Soft Launch Quota <span *ngIf="project?.quotaByStarts===true">By Starts</span> : {{ project?.softLaunchQuota }}
            <div class="clearfix"></div>
            -->

            Quota<span *ngIf="project?.quotaByStarts === true"> By Starts</span>:
            {{ project?.fullLaunchQuota }}
            <div class="clearfix"></div>
            QC: {{ project?.fraudCheckType || 'None' }}
            <div class="clearfix"></div>
            PII:
            <span *ngIf="
                !project?.piiName &&
                !project?.piiFirstName &&
                !project?.piiEmail &&
                !project?.piiPhone &&
                !project?.piiPhoneValidation &&
                !project?.piiAddress &&
                !project?.piiState
               ; else piiInformation">None</span>
            <ng-template #piiInformation>
              {{ piiInfo }}
            </ng-template>
            <div class="clearfix"></div>
            UniqueIds:
            <span *ngIf="
                !project?.enableUniqueIds && project?.numberOfUniqueIds < 1
              ">None</span>
            <ng-container *ngIf="project?.enableUniqueIds || project?.numberOfUniqueIds > 1">
              <a [routerLink]="[]" (click)="showSampleIds($event)">
                <span *ngIf="project?.enableUniqueIds">
                  {{ project?.numberOfUniqueIds }} Enabled</span></a>
              <a [routerLink]="[]" (click)="showSampleIds($event)">
                <span *ngIf="!project?.enableUniqueIds">
                  {{ project?.numberOfUniqueIds }} Disabled</span></a>
            </ng-container>


            <ng-container *ngIf="this.auth.user.userDetails.dash != true">
              <div class="clearfix"></div>
              Target VIDs:
              <span *ngIf="
                  !project?.enableTargetVIDs && project?.numberOfTargetVIDs < 1
                ">None</span>
              <ng-container *ngIf="project?.enableTargetVIDs || project?.numberOfTargetVIDs > 1">

                <a [routerLink]="[]" (click)="showSampleTargetVIDs($event)">
                  <span *ngIf="project?.enableTargetVIDs">
                    {{ project?.numberOfTargetVIDs }} Enabled</span></a>
                <a [routerLink]="[]" (click)="showSampleTargetVIDs($event)">
                  <span *ngIf="!project?.enableTargetVIDs">
                    {{ project?.numberOfTargetVIDs }} Disabled</span></a>
              </ng-container>
            </ng-container>

            <div class="clearfix"></div>
            Show in Dash+:
            <span *ngIf="project?.visibleToClient === true">Yes</span>
            <span *ngIf="project?.visibleToClient !== true">No</span>

          </div>
          <div class="xs-col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            SellCPI: {{ project?.sellCPI | currency }}
            <div class="clearfix"></div>
            LOI: {{ project?.loi }} minutes
            <div class="clearfix"></div>
            Est. IR: {{ project?.estimateIR }}
            <div class="clearfix"></div>
            Category: {{ project?.category }}
            <div class="clearfix"></div>
            Device Type: {{ project?.deviceType }}
          </div>
        </div>

        <div *ngIf="projectlist !== ''" class="row mt-2">
          <div class="col">
            Other Projects: {{ this.projectlist }}
            <div class="clearfix"></div>
            Dupe Other Projects On:
            <span *ngIf="project?.dupeOtherProjectsOnComplete">Complete </span>
            <span *ngIf="project?.dupeOtherProjectsOnStart">Start </span>
            <span *ngIf="project?.dupeOtherProjectsOnQC">QC </span>
            <span *ngIf="project?.dupeOtherProjectsOnTerm">Terminate </span>
            <span *ngIf="project?.dupeOtherProjectsOnOQ">OQ </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div #surveyContainer class="row mb-3">
  <div class="col">
    <div class="card shadow-sm">
      <div class="card-header lead">Survey Url</div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <a href="{{ project?.surveyUrl }}" target="_blank" class="card-link">{{ project?.surveyUrl }}</a>
            &nbsp;<i ngxClipboard [cbContent]="project?.surveyUrl" [container]="surveyContainer"
              (click)="copyContent(project?.surveyUrl)" class="fa fa-copy" style="font-size: 12px"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card shadow-sm">
      <div class="card-header lead">Test Survey Url</div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <a href="{{ project?.testSurveyUrl }}" target="_blank" class="card-link">{{ project?.testSurveyUrl }}</a>
            &nbsp;<i ngxClipboard [cbContent]="project?.testSurveyUrl" [container]="surveyContainer"
              (click)="copyContent(project?.testSurveyUrl)" class="fa fa-copy" style="font-size: 12px"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-project-performance *ngIf="participants.length > 0" [participants]="participants"></app-project-performance>
<app-targets *ngIf="projectId.length > 0" [projectId]="projectId"></app-targets>
<app-quotas *ngIf="projectId.length > 0" [projectId]="projectId"></app-quotas>
<app-notes *ngIf="projectId.length > 0" [projectId]="projectId"></app-notes>

<div class="row mb-3">
  <div class="col">
    <div class="card">
      <div class="card-header lead">
        Vendors
      </div>
      <div #vendorContainer class="card-body">
        <div class="row">
          <p-table [value]="project?.segments" [tableStyle]="{ 'min-width': '20rem' }" styleClass="p-datatable-striped"
            dataKey="vendorName" [autoLayout]="true" [paginator]="false">
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="vendorName">Vendor</th>
                <th pSortableColumn="segmentStatus">Status</th>
                <th pSortableColumn="vendorFullLaunchQuota">Quota</th>
                <th pSortableColumn="buyCPI">Buy CPI</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-vendor let-expanded="expanded" let-editing="editing" let-ri="rowIndex">
              <tr>
                <td class="limitedText">
                  <a href="/vendors/edit/{{ vendor.vendorId }}" target="_blank" class="card-link">{{ vendor.vendorName }}</a>
                </td>
                <td>
                  {{ vendor?.segmentStatus?.replace('Full Launch','Live') }}
                </td>
                <td>
                  {{ vendor?.vendorFullLaunchQuota }}
                </td>
                <td>
                  {{ vendor?.buyCPI | currency }}
                </td>
                <td class="m-0 p-0">
                  <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text"
                    title="Update Vendor URLs" (click)="toggleModal(content, vendor)"></button>
                </td>
                <td class="m-0 ps-0 pt-0 pb-0 pe-1">
                  <button pButton pRipple type="button" icon="pi pi-info-circle"
                    class="p-button-rounded p-button-text p-button-plain" title="View Vendor URLs"
                    data-bs-toggle="modal" data-bs-target="#vendorUrlModal" (click)="setCurrentVendor(vendor)"></button>
                </td>
                <td class="m-0 p-0">
                  <button pButton pRipple type="button" icon="pi pi-link"
                    class="p-button-rounded p-button-text p-button-plain" ngxClipboard [cbContent]="vendor?.entryUrl"
                    [container]="vendorContainer" (click)="copyContent(vendor?.entryUrl)"
                    [title]="'Copy survey entry URL: ' + vendor?.entryUrl"></button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="vendorUrlModal" tabindex="-1" role="dialog" aria-labelledby="vendorUrlModalTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="vendorUrlModalTitle">Vendor Urls</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div #vendorModal class="modal-body">
        <div class="row mb-3">
          <div class="card shadow-sm">
            <div class="card-header lead">
              <span>Complete <small>(&serverCall=true)</small></span>
            </div>
            <div class="card-body">
              <h5 class="card-title">Complete Url</h5>
              <h6 class="card-subtitle mb-2 text-muted">
                Update status with complete
              </h6>
              <a href="{{ currentVendor?.completeUrl }}" target="_blank" class="card-link">{{ currentVendor?.completeUrl
                }}</a>
              &nbsp;<i ngxClipboard [cbContent]="currentVendor?.completeUrl" [container]="vendorModal"
                (click)="copyContent(currentVendor?.completeUrl)" class="fa fa-copy" style="font-size: 12px"></i>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="card shadow-sm">
            <div class="card-header lead">
              <span>QC <small>(&serverCall=true)</small></span>
            </div>
            <div class="card-body">
              <h5 class="card-title">Term Url</h5>
              <h6 class="card-subtitle mb-2 text-muted">
                Update status with Term
              </h6>
              <a href="{{ currentVendor?.termUrl }}" target="_blank" class="card-link">{{ currentVendor?.termUrl }}</a>
              &nbsp;<i ngxClipboard [cbContent]="currentVendor?.termUrl" [container]="vendorModal"
                (click)="copyContent(currentVendor?.termUrl)" class="fa fa-copy" style="font-size: 12px"></i>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="card shadow-sm">
            <div class="card-header lead">
              <span>OQ <small>(&serverCall=true)</small></span>
            </div>
            <div class="card-body">
              <h5 class="card-title">OQ Url</h5>
              <h6 class="card-subtitle mb-2 text-muted">
                Update status with OQ
              </h6>
              <a href="{{ currentVendor?.oqUrl }}" target="_blank" class="card-link">{{ currentVendor?.oqUrl }}</a>
              &nbsp;<i ngxClipboard [cbContent]="currentVendor?.oqUrl" [container]="vendorModal"
                (click)="copyContent(currentVendor?.oqUrl)" class="fa fa-copy" style="font-size: 12px"></i>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="card shadow-sm">
            <div class="card-header lead">
              <span>Term <small>(&serverCall=true)</small></span>
            </div>
            <div class="card-body">
              <h5 class="card-title">QC Url</h5>
              <h6 class="card-subtitle mb-2 text-muted">
                Update status with QC
              </h6>
              <a href="{{ currentVendor?.qcUrl }}" target="_blank" class="card-link">{{ currentVendor?.qcUrl }}</a>
              &nbsp;<i ngxClipboard [cbContent]="currentVendor?.qcUrl" [container]="vendorModal"
                (click)="copyContent(currentVendor?.qcUrl)" class="fa fa-copy" style="font-size: 12px"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Vendor URL's</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <form [formGroup]="form">
    <div class="modal-body">
      <div class="form-row">
        <input type="hidden" formControlName="segmentId" class="form-control" />

        <div class="row">
          <div class="col">
            <label class="lead" for="completeUrl">Complete Url </label>
            <input type="url" formControlName="completeUrl" pattern="https?://.*"
              placeholder="https://vendor.com?status=complete&panelistId=#panid#&participantId=#parid#"
              class="form-control" [ngClass]="{ 'is-invalid': submitted && f.completeUrl.errors }" />
            <div *ngIf="submitted && f.completeUrl.errors" class="invalid-feedback">
              <div *ngIf="f.completeUrl.errors?.required">
                Complete Url is required
              </div>
              <div *ngIf="f.completeUrl.errors?.pattern">
                http:// or https://
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <label class="lead" for="termUrl">Term Url</label>
            <input type="url" pattern="https?://.*"
              placeholder="https://vendor.com?status=term&panelistId=#panid#&participantId=#parid#"
              formControlName="termUrl" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.termUrl.errors }" />
            <div *ngIf="submitted && f.termUrl.errors" class="invalid-feedback">
              <div *ngIf="f.termUrl.errors?.required">Term Url is required</div>
              <div *ngIf="f.termUrl.errors?.pattern">http:// or https://</div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <label class="lead" for="oqUrl">OQ Url</label>
            <input type="url" pattern="https?://.*"
              placeholder="https://vendor.com?status=oq&panelistId=#panid#&participantId=#parid#"
              formControlName="oqUrl" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.oqUrl.errors }" />
            <div *ngIf="submitted && f.oqUrl.errors" class="invalid-feedback">
              <div *ngIf="f.oqUrl.errors?.required">Live Url is required</div>
              <div *ngIf="f.oqUrl.errors?.pattern">http:// or https://</div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <label class="lead" for="qcUrl">QC Url</label>
            <input type="url" pattern="https?://.*"
              placeholder="https://vendor.com?status=qc&panelistId=#panid#&participantId=#parid#"
              formControlName="qcUrl" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.qcUrl.errors }" />
            <div *ngIf="submitted && f.qcUrl.errors" class="invalid-feedback">
              <div *ngIf="f.qcUrl.errors?.required">QC Url is required</div>
              <div *ngIf="f.qcUrl.errors?.pattern">http:// or https://</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <label class="lead" for="signingKey">Signing Key</label>
          <label class="float-end"><input type="checkbox" formControlName="signRedirects" class="form-check-inline me-2"
              (change)="signRedirectsChanged($event)" />Sign Redirects?</label>
          <input type="text" formControlName="signingKey" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.signingKey.errors }" />
          <div *ngIf="submitted && f.signingKey.errors" class="invalid-feedback">
            <div *ngIf="f.signingKey.errors?.required">Signing Key is required</div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="form-group mt-3">
        <button [disabled]="loading" (click)="onSubmit(content)" class="btn btn-primary">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Save
        </button>
        <a (click)="modal.dismiss('Cancel click')" class="btn btn-link">Cancel</a>
      </div>
    </div>
  </form>
</ng-template>

<p-overlayPanel #op [style]="{ width: '425px' }">
  <div>
    <form [formGroup]="exportParticipantForm" (ngSubmit)="onSubmitExportParticipants()">
      <div class="form-group mb-3">
        <div class="row">
          <div class="col-md-6">
            <label>Start Date</label>
            <input class="form-control" (change)="onDownloadParticipantDatePickerChange()" formControlName="startDate" type="date" class="form-control" placeholder="Date" [ngClass]="{'is-invalid': (exportParticipantForm.controls.startDate.errors || exportParticipantForm.controls.startDate.errors)}">
          </div>
          <div class="col-md-6">
            <label>End Date</label>
            <input class="form-control" (change)="onDownloadParticipantDatePickerChange()" formControlName="endDate" type="date" class="form-control" placeholder="Date" [ngClass]="{'is-invalid': (exportParticipantForm.controls.startDate.errors || exportParticipantForm.controls.startDate.errors) }">
          </div>
        </div>
        <div *ngIf="exportParticipantForm.controls.endDate.errors?.invalid" class="invalid-feedback d-block">
          Start date must be earlier than or equal to end date. Please adjust the dates accordingly.
        </div>

        <div *ngIf="exportParticipantForm.controls.endDate.errors?.bothRequiredInvalid" class="invalid-feedback d-block">
          Both fields must have a value.
        </div>

      </div>
      <div class="form-group mb-3">
        <label>Vendor</label>
        <ng-multiselect-dropdown
          [settings]="vendorsDropdownSettings"
          [data]="vendorExportDropdownValues"
          formControlName="vendors"
          [enableCheckAll]="true">
        </ng-multiselect-dropdown>
      </div>

      <div class="form-group mb-3">
        <label>Survey Status</label>
        <ng-multiselect-dropdown
          [settings]="surveyStatusDropdownSettings"
          [data]="surveyStatusExportDropdownValues"
          formControlName="surveyStatus"
          [enableCheckAll]="true">
        </ng-multiselect-dropdown>
      </div>

      <button [disabled]="downloading" type="submit" class="btn btn-primary">
        <span *ngIf="downloading" class="spinner-border spinner-border-sm mr-1"></span>
          <i *ngIf="!downloading" class="bi bi-download"></i>
          Export
      </button>
    </form>
  </div>
</p-overlayPanel>

