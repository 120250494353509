import { Component, Input, OnInit } from '@angular/core';
import { groupCollapsed } from 'console';
import { ToastrService } from 'ngx-toastr';
import { ProjectService } from 'src/app/_services/project.service';

@Component({
  selector: 'app-quotas',
  templateUrl: './quotas.component.html',
  styleUrls: ['./quotas.component.css']
})
export class QuotasComponent implements OnInit {

  @Input() projectId='';
  quotas:any;
  totals:any[]=[];
  constructor(
    private projectService: ProjectService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.getQuotas();
  }

  getQuotas(){
    this.projectService.projectQuotas(this.projectId).subscribe(data=>{
      this.quotas= data;
    }, error =>{
      this.toastr.error('Failed to fetch quotas');
    }, () => {

    })
  }
}
