import { Component, Input, OnInit } from '@angular/core';
import { question } from 'ngx-bootstrap-icons';
import { ToastrService } from 'ngx-toastr';
import { Question } from 'src/app/_models/question';
import { ProjectService } from 'src/app/_services/project.service';
import { QuestionService } from 'src/app/_services/question.service';
import { ProjectTarget } from '../../../_models/project-target';

@Component({
  selector: 'app-targets',
  templateUrl: './targets.component.html',
  styleUrls: ['./targets.component.css']
})

export class TargetsComponent implements OnInit {
  @Input() projectId:string = '';
  questions: Question[] = [];
  targets: ProjectTarget[] = [];
  targetsForView: any[] = [];
  constructor(
    private questionService: QuestionService,
    private projectService: ProjectService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    // get questions
    // get targets
    this.getQuestions();
    this.getProjectTargets();
  }

  loadTargets() {
    let list:any[] = [];

    if(this.questions.length < 1 || this.targets.length < 1) {
      this.targetsForView = list;
      return; //not ready
    }

    this.targets.forEach(key => {
      const question = this.questions.find(item => item.questionKey == key.questionKey);
      //get question
      if(key.questionKey === 'AGE') {
        if(key.options?.length > 1){
          let min  = key.options[0];
          let max =  key.options[key.options.length - 1];
          // get highest number
          list.push({
            questionKey:'AGE',
            optionText: '' + min + '-' + max
          });
        }
      }
      else if(key.questionKey === 'ZIP') {
        list.push({
          questionKey: 'ZIP',
          optionText: '' + key.options.length + ' Zips selected'
        });
      }
      else if(key.questionKey === 'MSA') {
        list.push({
          questionKey: 'MSA',
          optionText: '' + key.options.length + ' MSA selected'
        });
      }
      else if(key.questionKey === 'DMA') {
        list.push({
          questionKey: 'DMA',
          optionText: '' + key.options.length + ' DMA selected'
        });
      }
      else {
        let options:any[] = [];
        key.options?.forEach(id => {
          var option = question?.questionOptions.find(item => item.id == +id);
          options.push(option?.optionText);
        });

        list.push({
          questionKey:key.questionKey,
          optionText: options.join(", ")
        });
      }
    });

    this.targetsForView = list.sort().reverse();
    return;
  }

  getProjectTargets() {
    this.projectService.projectTargets(this.projectId).subscribe(data=>{
      this.targets= data;
    }, error =>{
      this.toastr.error('Unable to fetch questions');
    }, () => {
      this.loadTargets();
    })
  }

  getQuestions(){
    this.questionService.allQuestions().subscribe(data=>{
      this.questions= data;
    }, error =>{
      this.toastr.error('Unable to fetch questions');
    }, () => {
      this.loadTargets();
    })
  }
}
