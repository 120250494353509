import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProjectForCreate } from '../_models/project';
import { ProjectForEdit } from '../_models/project-for-edit';
import { ProjectForList } from '../_models/project-for-list';
import { ParticipantExportDownload } from '../_models/participant-export-download';
import { Note } from "../_models/note";
import { ClientUser } from "../_models/client-user";
import { ExportParticipantsFilter } from '../_models/export-participants-filter';
import { ProjectTarget } from '../_models/project-target';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(
    private http: HttpClient
  ) { }

  add(model: ProjectForCreate) {
    return this.http.post(`${environment.apiUrl}/api/project`,model)
  }

  find(projectId:string) {
    return this.http.get<ProjectForList>(`${environment.apiUrl}/api/project/`+ projectId)
  }


  projectParticipants(projectId:string) {
    return this.http.get<any[]>(`${environment.apiUrl}/api/project/projectparticipants/`+ projectId)
  }


  projectQuotas(projectId:string) {
    return this.http.get<any[]>(`${environment.apiUrl}/api/project/projectquotas/`+ projectId)
  }


  projectTargets(projectId:string) {
    return this.http.get<ProjectTarget[]>(`${environment.apiUrl}/api/project/projecttargets/`+ projectId)
  }

  findForEdit(projectId:string) {
    return this.http.get<ProjectForEdit>(`${environment.apiUrl}/api/project/edit/`+ projectId)
  }

  update(model: ProjectForEdit) {
    return this.http.put(`${environment.apiUrl}/api/project`,model)
  }


  updateUrls(model: any) {
    return this.http.put(`${environment.apiUrl}/api/project/urls`,model)
  }


  list() {
    return this.http.get<ProjectForList[]>(`${environment.apiUrl}/api/project`)
  }

  listActiveProjects() {
    return this.http.get<ProjectForList[]>(`${environment.apiUrl}/api/project/active`)
  }

  listInvoicedProjects() {
    return this.http.get<ProjectForList[]>(`${environment.apiUrl}/api/project/invoiced`)
  }


  downloadParticipantsCSV(projectId:string, filter: ExportParticipantsFilter){
    return this.http.post<ParticipantExportDownload>(`${environment.apiUrl}/api/project/export?projectId=`+projectId, filter);
  }

  downloadProjectsCSV(status:string){
    return this.http.get(`${environment.apiUrl}/api/project/projectscsv?status=`+status, { responseType: 'text'});
  }

  clone(projectId: string) {
    return this.http.get<ProjectForEdit>(`${environment.apiUrl}/api/project/clone?projectId=`+projectId);
  }
}
