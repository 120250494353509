import { Auth } from '@aws-amplify/auth';
import { AuthService } from 'src/app/_services/auth.service';
import { SegmentMarker } from './../../../_models/demographics';
import { NubbleService } from './../../../_services/nubble.service';
import { Marker } from './../../../_models/marker';
import { element } from 'protractor';
import { map } from 'rxjs/operators';
import { Demographics, Quota, Target } from '../../../_models/demographics';
import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { QuestionService } from 'src/app/_services/question.service';
import { ToastrService } from 'ngx-toastr';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Question } from '../../../_models/question';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgxDropdownConfig } from 'ngx-select-dropdown';

@Component({
  selector: 'app-demographics',
  templateUrl: './demographics.component.html',
  styleUrls: ['./demographics.component.css'],
  providers:[
  {
    provide: NG_VALUE_ACCESSOR,multi:true,useExisting: forwardRef(()=>DemographicsComponent)
  }],
})

// NgModel ControlValueAccessor
//https://www.youtube.com/watch?v=lNwsnuWSD7o

export class DemographicsComponent implements OnInit, ControlValueAccessor {

  @Input() isEngagementPage: boolean = false;

  // questions
  // object
  // ngmodel
  @Input() quotaByTargets: boolean=false;
  @Input() fullLaunchQuota: number=200;
  @Input() model: Demographics = {
    targets: [],
    quotas: [],
    enablePrescreenerQuotas:false,
    zips: [],
    division: [],
    region: [],
    markers: [],
    ageTarget: {
      target: {
        min:0,
        max:0
      },
      buckets:[]
    }
  };


  bucketReady: boolean = false;
  questionsForDD: any[] = [];
  selectedDemographics: any[] = []; //For dropdown
  selectedQuestions: Question[] = []
  questions: Question[] = [];
  quotas: any[] = [];
  createBucketName: string = '';
  markerList: Marker[] = [];
  selectedMarkers: Marker[] = []
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    enableCheckAll: false,
    idField: 'questionKey',
    textField: 'questionKey',
    allowSearchFilter: true
  };


  // See documentation ngxdropdown config https://www.npmjs.com/package/ngx-select-dropdown
  multiconfig = {
    displayKey:"optionText",
    search:false,
    height: 'auto',
    customComparator: ()=>{},
    limitTo: 0,
    moreText: 'more',
    noResultsFound: 'No results found!',
    searchPlaceholder:'Search',
    searchOnKey: 'optionText',
    }

    markerconfig = {
      displayKey:"name",
      search:false,
      height: '350px',
      limitTo: 0,
      moreText: 'more',
      noResultsFound: 'No results found!',
      searchPlaceholder:'Search',
      searchOnKey: 'name',
      selectAllLabel: 'Select all',
      enableSelectAll: true,
    }

    onChange = (_: any) => {};
    onTouched = () => {};

  constructor(
    private questionService: QuestionService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private nubble: NubbleService,
    public auth: AuthService
  ) {
    // todo update selected options
  }

  ngOnInit() {
    // only get markers for qrious
    if(this.auth.user.userDetails.dash !=true){
      this.getMarkers();
    }

    this.getQuestions();
  }

  writeValue(obj: any): void {
    this.model= obj;

    if (this.questions.length > 0) { // wait for questions to be loded
      this.selectedDemographics = this.model.targets.map(item => item.questionKey);
      this.reloadQuestions();
      this.targetChanged();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }



  toggleModal(content:any, target: any){

    if(this.model.ageTarget==null){

      this.model.ageTarget = {
          target: {
            min:0,
            max:0
          },
          buckets:[]
      };
    }

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  // calls
  getQuestions() {
    this.questionService.allQuestions().subscribe((data) => {
      this.questions.push(...data);
      this.questionsForDD = this.questions.filter(x=>x.questionKey !== 'DOB').map(item => item.questionKey);
    }, (error) => {
      this.toastr.error('Unable to fetch questions');
    }, () => {
      if(this.questionsForDD.filter(x => x === 'AGE').length < 1) { 
        this.questions.push({questionId:12,questionKey:'AGE', questionType:'', questionOptions:[],questionText:'',language:'',country:'',category:[]});
        this.questionsForDD.push('AGE');
      }
      this.writeValue(this.model)
    })
  }

  markersChanged(){
    this.model.markers =[];
    this.selectedMarkers.forEach(element => {
      this.model.markers.push({id:element.id, name: element.name});
    });
  }


  onDemograpicAdd(event: any) {
    // Add question to targets
    const newTarget:Target = {
      questionKey: event,
      ids:[]
    };

    this.model.targets.push(newTarget);
    this.reloadQuestions();
  }

  onDemograpicRemove(event) {
    switch (event.toUpperCase()) {
      case 'AGE':
        this.model.ageTarget = {
          target: {
            min:0,
            max:0
          },
          buckets:[]
        }
        break;
      case 'ZIP':
        this.model.zips = [];
        break;
      default:
        break;
    }
    this.model.targets = this.model.targets.filter(item => item.questionKey != event);
    this.reloadQuestions();
  }

  reloadQuestions() {
  //filter questions to selected
    this.selectedQuestions = this.questions.filter(item => this.selectedDemographics.includes(item.questionKey));

    // get selected options
    this.selectedQuestions.forEach(element=> {
      var target = this.model.targets.find(item => item.questionKey == element.questionKey);
      var ids = target?.ids??[]; // get selected ids from target
      element.selectedOptions = element.questionOptions.filter(item => ids.includes(item.id)); // filter options of selected
    });
    this.loadQuotas();
  }


  dropdownConfig(target:any) {
    var config: NgxDropdownConfig = {
      displayKey:"optionText", //if objects array passed which key to be displayed defaults to description
      search: target.questionOptions.length > 10, //true/false for the search functionlity defaults to false,
      height: '350px', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
      placeholder: target.questionKey, // text to be displayed when no item is selected defaults to Select,
      limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
      searchPlaceholder:'Search', // label thats displayed in search input,
      searchOnKey: 'optionText', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
      clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
      inputDirection: 'ltr', // the direction of the search input can be rtl or ltr(default)
      selectAllLabel: 'Select all', // label that is displayed in multiple selection for select all
      enableSelectAll: true, // enable select all option to select all available items, default is false
    }

    return config;
  }

  removeAgeBucket(index:number): void{
    this.model.ageTarget.buckets.splice(index, 1);(index);
  }

  addAgeBucket() {
    this.model.ageTarget.buckets.push(
      {
        min:0,
        max:0,
      }
    )
  }

  uploadDemographics(fileInput: any, demographicProperty: string) {
    let file = fileInput.target.files[0];
    let reader: FileReader = new FileReader();
    reader.readAsText(file);
    reader.onload = (e) => {
      let csv: string = reader.result as string;
      csv = csv.replace(/[\n\r]+/g, '-');
      switch (demographicProperty.toUpperCase()) {
        case 'ZIPS':
          this.model.zips = csv.split("-");
          this.model.zips = this.model.zips.filter(item=>item.length > 1);
          break;
      }
    }
  }

  targetChanged() {
    this.selectedQuestions.forEach((question) => {
      var targetToUpdate = this.model.targets.find(item => item.questionKey === question.questionKey);
      if (targetToUpdate != null) {
        targetToUpdate.ids = question.selectedOptions!.map(item =>item.id);
        this.model.targets = this.model.targets.filter(item => item.questionKey !== question.questionKey); // remove
        this.model.targets.push(targetToUpdate); // add new
      }
    });

    this.loadQuotas();
  }

  numberChange(item) {
    item.percent = ((item.number/this.fullLaunchQuota)*100).toFixed(2);
  }

  percentChange(item) {
    item.number =Math.ceil((item.percent/100)*this.fullLaunchQuota);
  }

  loadQuotas() {
      let oldQuotas = this.model.quotas;
      let buckets = this.model.quotas.filter(item => item.optionIds.length > 1);
      const currentTargets =JSON.parse(JSON.stringify(this.model.targets));// copy array without reference
      let targets: any[] = [];

      if(buckets.length > 0) {
        currentTargets.forEach(target=> {
          let newTarget = target;
          let ids:string[] = [];
          target.ids.forEach(tid=>{
            let targetId = +tid;// change to int
            let alreadyInUse = false;

            buckets.forEach(bucket=>{
              if(target.questionKey===bucket.questionKey){
                bucket.optionIds.forEach(id=>{
                  if(targetId===id){
                    alreadyInUse=true;
                  }
                });
              }
            });

            if(alreadyInUse===false){
              ids.push(tid);
            }
          });
          newTarget.ids=ids;
          targets.push(newTarget);
        //if not in bucket add
        });
      }
      else {
        targets = currentTargets;
      }

      // find buckets for
      // only add targets not in Buckets
      // add buckets
      this.model.quotas = [];

      targets.forEach(target=> {
              var question = this.questions.find(q => q.questionKey==target.questionKey);
              if(target.questionKey==='AGE'){
                this.model.ageTarget.buckets.forEach(bucket=>{
                  this.model.quotas.push({
                      questionKey:'AGE',
                      optionIds:[],
                      optionText: bucket.min + '-' + bucket.max,
                      hardStop: true,
                      number:0,
                      percent:0,
                      min:bucket.min,
                      max:bucket.max
                  });
                });
              }
              else{
                target.ids.forEach(id=>{

                  var option = question?.questionOptions.find(q => q.id==+id);
                  let options = [+id];

                  this.model.quotas.push({
                    questionKey:target.questionKey,
                    optionIds:options,
                    optionText: option?.optionText || '*Notify admin*',
                    hardStop: true,
                    number:0,
                    percent:0,
                    min:0,
                    max:0
                  });
                });
              }
          });

          //add buckets back in
          buckets.forEach(bucket=>{
            this.model.quotas.push({
              questionKey:bucket.questionKey,
              optionIds:bucket.optionIds,
              optionText:bucket.optionText,
              hardStop: true,
              number:0,
              percent:0,
              min:0,
              max:0
            });
          });

      this.model.quotas.forEach(item=> {
        let oldQuota = oldQuotas.find(q => q.questionKey==item.questionKey && (q.optionText.toLowerCase() == item.optionText.toLocaleLowerCase()));
        if(oldQuota !=null){
          item.number =  oldQuota.number;
          item.percent  = parseFloat(((oldQuota.number/this.fullLaunchQuota)*100).toFixed(2)) ;
          item.hardStop = oldQuota.hardStop;
        }
      });
  }

  saveAgeTargets() {
    this.loadQuotas();
  }

  checkChange(event) {
    var checked = this.model.quotas.filter(item => item.checked === true);
    if(checked.length > 0) {
      this.disableQuotas();
    }
    else{
      this.enableQuotas();
    }

    if(checked.length > 1) {
      this.bucketReady=true;
    }
    else{
      this.bucketReady=false;
    }

    // if none checked enable all
    // enable all in same demographic
  }


  disableQuotas(){
    // find one thats checked
    //get demog
    var checked =  this.model.quotas.find(q => q.checked===true);
    if(checked){
      this.model.quotas.forEach(item=>{
        if(item.questionKey===checked?.questionKey){
          item.disable = false;
        }
        else{
          item.disable = true;
        }
      });
    }
  }

  enableQuotas(){
    this.model.quotas.forEach(item=>{
      item.disable = false;
    });
  }

  arrayEquals(a, b) {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
  }

  createBucket() {
    // hide Modal
    // Remove seletected items
    let checkedQuotas = this.model.quotas.filter(item => item.checked === true);
    this.model.quotas = this.model.quotas.filter(item => item.checked !== true);
    let ids:number[] = [];

    checkedQuotas.forEach(item=>{
        item.optionIds.forEach(id=>{
          ids.push(id);
        });
    });

    this.model.quotas.push({
      questionKey:checkedQuotas[0].questionKey,
      optionIds:ids,
      optionText:this.createBucketName,
      hardStop: true,
      number:0,
      percent:0,
      min:0,
      max:0
  });

    // create bucket with selected items and name
    this.bucketReady=false;
    this.enableQuotas();
    // clear name from modal
    this.createBucketName ='';
  }

  createBucketModal(e:any,bucket:any) {
    e.preventDefault();
    this.modalService.open(bucket, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  explodeBucket(bucket:Quota) {
    // check target exists
    let target =  this.model.targets.find(item => item.questionKey == bucket.questionKey);
    this.model.quotas = this.model.quotas.filter(item => !(item.questionKey == bucket.questionKey && this.arrayEquals(item.optionIds,bucket.optionIds)));

    if(!target) {
      return;
    }

    var question = this.questions.find(q => q.questionKey==bucket.questionKey);
    bucket.optionIds.forEach(id =>{
      var option = question?.questionOptions.find(q => q.id==+id);

      if(target?.ids.includes(id)){

            this.model.quotas.push({
                      questionKey:bucket.questionKey,
                      optionIds:[id],
                      optionText: option?.optionText||'',
                      hardStop: true,
                      number:0,
                      percent:0,
                      min:bucket.min,
                      max:bucket.max
                  });
      }
    });
    // target removed ?? delete
    // create quota for each optionIds
  }

  getMarkers() {
    this.nubble.listMarkers().subscribe(data=>{
      this.markerList = data;
    }, error =>{
      this.toastr.error('Failed to fetch markers');
    }, () => {
      if(!this.markerList){
        this.markerList=[];
      }
      if(!this.model?.markers){
        this.model.markers=[];
      }
      this.selectedMarkers = this.markerList?.filter(marker=> (this.model?.markers.filter(x=>x.id === marker.id).length>0));
      this.writeValue(this.model)
    })
  }

}